import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import "./CashPaymentModal.css";

const CashPaymentModal = (props) => {
  const {
    cashPaymentModal,
    toggleCashPaymentModal,
    receiptTotal,
    cashTransaction,
  } = props;
  const [amountTendered, setAmountTendered] = useState(0);

  const [changeOwed, setChangeOwed] = useState(0);

  //handle amount tendered change
  const handleAmountTenderedChange = (e) => {
    const maxLength = 15;
    const amount = e.target.value;
    let owedAmount = 0;
    if (amount.length >= maxLength) {
      return;
    } else if (amount >= receiptTotal) {
      setAmountTendered(amount.replace(/^0+/, ""));
      owedAmount = Number(amount).toFixed(2) - Number(receiptTotal).toFixed(2);
      setChangeOwed(owedAmount);
    } else {
      setAmountTendered(amount.replace(/^0+/, ""));
      setChangeOwed(0);
    }
  };

  //handle price buttons click
  const handleFixedAmountButton = (e, amount) => {
    e.preventDefault();
    let owedAmount = 0;
    const TenderedAmount = Number(amountTendered) + amount;
    setAmountTendered(TenderedAmount);
    if (TenderedAmount > receiptTotal) {
      owedAmount =
        Number(TenderedAmount).toFixed(2) - Number(receiptTotal).toFixed(2);
      setChangeOwed(owedAmount);
    } else {
      setChangeOwed(0);
    }
  };

  //cancel the transaction
  const handleCancel = (e) => {
    e.preventDefault();
    hideModal();
  };

  //hide the modal and reset values
  const hideModal = () => {
    setAmountTendered(0);
    setChangeOwed(0);
    toggleCashPaymentModal();
  };

  //complete the transaction
  const completeTransaction = (e) => {
    e.preventDefault();
    cashTransaction(
      Number(amountTendered).toFixed(2),
      Number(changeOwed).toFixed(2)
    );
    toggleCashPaymentModal();
    setAmountTendered(0);
    setChangeOwed(0);
  };

  return (
    <Modal
      className="modal-wrapper"
      show={cashPaymentModal}
      size="md"
      onHide={hideModal}
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Cash Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3">
          <div className="col-12">
            <label className="form-label">Receipt Total</label>
            <input
              value={`$ ${Number(receiptTotal).toFixed(2)}`}
              type="text"
              className="form-control"
              disabled
            />
          </div>

          <div className="col-12">
            <label className="form-label">Amount Tendered</label>
            <input
              value={amountTendered}
              type="number"
              className="form-control"
              onChange={handleAmountTenderedChange}
              min={0}
            />
          </div>

          <div className="co-12 d-flex flex-wrap g-2">
            <button
              type="button"
              className="btn fixed-amount-button"
              onClick={(e) => handleFixedAmountButton(e, 1)}
            >
              $1
            </button>
            <button
              type="button"
              className="btn fixed-amount-button"
              onClick={(e) => handleFixedAmountButton(e, 5)}
            >
              $5
            </button>
            <button
              type="button"
              className="btn fixed-amount-button"
              onClick={(e) => handleFixedAmountButton(e, 10)}
            >
              $10
            </button>
            <button
              type="button"
              className="btn fixed-amount-button"
              onClick={(e) => handleFixedAmountButton(e, 20)}
            >
              $20
            </button>
            <button
              type="button"
              className="btn fixed-amount-button"
              onClick={(e) => handleFixedAmountButton(e, 50)}
            >
              $50
            </button>
            <button
              type="button"
              className="btn fixed-amount-button"
              onClick={(e) => handleFixedAmountButton(e, 100)}
            >
              $100
            </button>
          </div>

          <div className="col-12">
            <label className="form-label">Change Owed</label>
            <input
              value={`$ ${Number(changeOwed).toFixed(2)}`}
              type="text"
              className="form-control"
              disabled
            />
          </div>
          <div className="col-md-6">
            <button
              type="submit"
              className="btn submit-button"
              disabled={amountTendered >= receiptTotal ? false : true}
              onClick={(e) => completeTransaction(e)}
            >
              Complete
            </button>
          </div>
          <div className="col-md-6">
            <button
              className="btn cancel-button"
              onClick={(e) => handleCancel(e)}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CashPaymentModal;
