import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Table from "../../Components/Table/Table";
import { AiOutlineEdit, AiFillDelete } from "react-icons/ai";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import {
  LEFT_ALIGN,
  LEFT_ALIGN_WITH_BORDER,
  datatableStyle,
  paginationStyle,
} from "../../Utils/Util";
import TableWare from "../../Components/DataTable/TableWare.tsx";
import * as XLSX from "xlsx";
import { StoreContext } from "../../Components/StoreContext.jsx";
import { Constants } from "../../Constant/index.js";
import { Modal } from "react-bootstrap";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const { selectedStore } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [orderByDir, setOrderByDir] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchString, setSearchString] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const importFileRef = useRef();
  const [isImportModal, setIsImportModal] = useState(false);
  const [importDuplicateRecords, setImportDuplicateRecords] = useState([]);

  useEffect(() => {
    getCustomers();
  }, []);

  const headers = [
    {
      id: "First Name",
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      style: LEFT_ALIGN_WITH_BORDER,
    },
    {
      id: "Last Name",
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Current Balance",
      name: "Current Balance",
      selector: (row) => row.currentBalance,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Driver License",
      name: "Driver License",
      selector: (row) => row.driverLicense,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Address Line 1",
      name: "Address Line 1",
      selector: (row) => row.line1,
      sortable: true,
      style: LEFT_ALIGN,
    },

    {
      id: "Mobile",
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "State",
      name: "State",
      selector: (row) => row.state,
      sortable: true,
      style: LEFT_ALIGN,
    },

    {
      id: "Store",
      name: "Store",
      selector: (row) => row.store.name,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      cell: (row) => {
        return (
          <>
            <div className="action-icons-edit-del">
              <span>
                <Link to={`/edit-customer/${row.id}`}>
                  <AiOutlineEdit />
                </Link>
              </span>
              &nbsp;&nbsp;
              <span>
                <Link
                  onClick={() => {
                    delCustomer(row.id);
                  }}
                >
                  <AiFillDelete />
                </Link>
              </span>
            </div>
          </>
        );
      },
      name: "Action",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const importCustomerColumn = [
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      style: LEFT_ALIGN_WITH_BORDER,
    },
    {
      id: "Full Name",
      name: "Full Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
      style: LEFT_ALIGN_WITH_BORDER,
    },
  ];

  const getCustomers = async () => {
    const response = await api.get("customer");
    if (response && response.ok) {
      setCustomers(response.data.data);
    }
  };

  const delCustomer = async (id) => {
    const response = await api.delete(`customer/${id}`);
    if (response && response.ok) {
      notificationSvc.success("Customer Deleted Successfully");
      getCustomers();
    }
  };

  const handleFileUpload = async (e) => {
    let errors = [];
    let customers = [];
    let required = ["Email", "Full Name", "Phone Number", "Store Credit"];

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const dataStringLines = data.split(/\r\n|\n/);
      const headers = dataStringLines[0].split(",");
      required.forEach((key) => {
        if (!headers.includes(key)) {
          errors.push(`${key} is not found in file`);
        }
      });
      if (errors.length > 0) {
        notificationSvc.error(errors[0]);
        e.target.value = null;
        return;
      }
      for (let i = 1; i < dataStringLines.length; i++) {
        let item = dataStringLines[i].split(",");
        customers.push({
          email: item[headers.indexOf("Email")],
          firstName: item[headers.indexOf("Full Name")].slice(
            0,
            item[headers.indexOf("Full Name")].indexOf(" ")
          ),
          lastName: item[headers.indexOf("Full Name")].slice(
            item[headers.indexOf("Full Name")].indexOf(" ") + 1
          ),
          phoneNumber: item[headers.indexOf("Phone Number")],
          storeCredit: item[headers.indexOf("Store Credit")],
          store: {
            id: selectedStore?.value,
            name: selectedStore?.label,
          },
        });
      }

      // handle duplicate records in file
      let valueArr = customers.map((record) => record.email);
      let duplicateRecords = [];
      valueArr.forEach((value, index) => {
        valueArr.lastIndexOf(value) !== index && duplicateRecords.push(index);
      });

      if (duplicateRecords.length) {
        setImportDuplicateRecords(
          duplicateRecords.map((duplicateIndex) => {
            return customers[duplicateIndex];
          })
        );
        setIsImportModal(true);
        notificationSvc.error(
          "File cannot be imported because of duplicate records, Email must be unique"
        );
        e.target.value = null;
        return;
      }

      const response = await api.post(
        `bulk-upload-customers/${selectedStore?.value}`,
        customers
      );
      if (response.ok) {
        notificationSvc.success(response.data.message);
        if (response.data.data.length) {
          setImportDuplicateRecords(response.data.data);
          setIsImportModal(true);
        }
        getCustomers();
      }
      e.target.value = null;
    };
    reader?.readAsBinaryString(file);
  };

  return (
    <>
      <main>
        <div className="container-fluid">
          <div className="row page-header mt-3">
            <div className="col-4">
              <h2 className="heading-main-page">Customers</h2>
              <span>Total Customers : {customers.length.toString()}</span>
            </div>
            <div className="col-8 book-details-btn text-end">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="fileBtn upload-btn me-2 d-inline"
              >
                <label
                  htmlFor="file"
                  className="btn btn-indigo px-3"
                  onClick={() => importFileRef.current.click()}
                >
                  <input
                    type="file"
                    onChange={(e) => handleFileUpload(e)}
                    className="fileUpload"
                    id="file"
                    required
                    ref={importFileRef}
                    hidden
                  />
                  <button className="btn" type="submit">
                    Import from file
                  </button>
                </label>
              </form>
              <Link className="btn" to={"/add-customer"}>
                Add Customer
              </Link>
            </div>
          </div>
          <div className="see-customer mt-3">
            <div className="table-responsive">
              <TableWare
                json={customers}
                tableColumns={headers}
                customStyles={datatableStyle}
                paginationStyles={paginationStyle}
                enableSearch={false}
                loading={loading}
                enablePagination={totalCount > pageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalCount={totalCount}
                // onSearchChange={(str: any) => {
                //   setPageIndex(1);
                //   setSearchString(str);
                // }}
                onSortChange={(orderBy, orderByDir) => {
                  setPageIndex(1);
                  setOrderBy(orderBy);
                  setOrderByDir(orderByDir);
                }}
                onPageChange={setPageIndex}
              />
            </div>
          </div>
        </div>
      </main>
      {/* duplicate records modal */}
      <Modal
        show={isImportModal}
        size="lg"
        onHide={() => setIsImportModal(!isImportModal)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableWare
            json={importDuplicateRecords}
            tableColumns={importCustomerColumn}
            customStyles={datatableStyle}
            enablePagination={false}
            enableSearch={false}
            sortServer={false}
            loading={loading}
            onSortChange={(orderBy, orderByDir) => {
              setPageIndex(1);
              setOrderBy(orderBy);
              setOrderByDir(orderByDir);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Customer;
