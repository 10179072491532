import qz from "qz-tray";
import notificationSvc from "../../../Services/notification";
import { Constants } from "../../../Constant";
import { api } from "../../../Services/api-service";

const { printerSettingsConstants } = Constants;

// Function to connect to QZ Tray
export const connectToQZTray = () => {
  return qz.websocket
    .connect()
    .then(() => {
      console.log("Connected to QZ Tray");
    })
    .catch((error) => {
      console.log("Connect To QZ Error : ", error);
    });
};

export const getDefaultPrinter = async () => {
  const currentStore = JSON.parse(localStorage.getItem("selectedStore"));
  const response = await api.get(
    `printer-configurations/?storeId=${currentStore.id}`
  );
  if (response && response.ok && response.data.data) {
    const printer = response.data.data.filter(
      (printer) => printer.isDefault === true
    );
    if (printer.length) {
      sessionStorage.setItem(
        printerSettingsConstants.DEFAULT_PRINTER,
        JSON.stringify(printer[0])
      );
      return printer[0];
    } else {
      notificationSvc.error("please set default printer");
    }
  }
};

// Function to disconnect from QZ Tray
export const disconnectFromQZTray = () => {
  return qz.websocket
    .disconnect()
    .then(() => {
      console.log("Disconnected from QZ Tray");
    })
    .catch((error) => {
      console.log("Disconnect To QZ Error : ", error);
    });
};

export const printTestReceiptData = (printer, receiptData) => {
  connectToQZTray().then(async () => {
    await qz.printers
      .find(printer)
      .then(function (printer) {
        console.log("Printing on:", printer);

        // Print EPoS data to the default printer
        var configuration = qz.configs.create(printer);
        qz.print(configuration, receiptData)
          .then(() => {
            disconnectFromQZTray();
          })
          .catch(function (error) {
            notificationSvc.error(error.toString());
            console.error("Error printing:", error);
          });
      })
      .catch(function (error) {
        console.error("Error during find printer:", error);
      });
  });
};

//Function to print data
export const printReceiptData = async (receiptData) => {
  let printer;
  let isDefaultPrinter = sessionStorage.getItem(
    printerSettingsConstants.DEFAULT_PRINTER
  );
  if (isDefaultPrinter) {
    printer = JSON.parse(isDefaultPrinter);
  } else {
    printer = await getDefaultPrinter();
  }

  if (printer) {
    printTestReceiptData(printer.generalSettings.productName, receiptData);
  } else {
    notificationSvc.error("No Printer Device Found");
  }
};
