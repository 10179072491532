import React, { useState, useEffect } from "react";
import "./PrinterSettings.css";
import { Tab, Col, Nav, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import notificationSvc from "../../Services/notification";
import { api } from "../../Services/api-service.js";
import { Constants } from "../../Constant/index.js";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { printLabel } from "../../Pages/Inventory/zebraPrinter/zebraPrinter.js";
import { printTestReceiptData } from "../../Pages/Transactions/ReceipPrinters/printReceipt.js";

const { printerSettingsConstants } = Constants;
const PrinterSettings = (props) => {
  const {
    printerSettingModal,
    togglePrinterSettingModal,
    selectedStore,
    allPrinters,
    availableReceiptPrinters,
  } = props;

  const hideModal = () => {
    setSelectedPrinter();
    togglePrinterSettingModal();
    setPrinterName("");
    setSelectedReceiptPrinter();
  };

  const [selectedPrinter, setSelectedPrinter] = useState();
  const [defaultZebraPrinter, setDefaultZebraPrinter] = useState();
  const [printerName, setPrinterName] = useState();
  const [selectedReceiptPrinter, setSelectedReceiptPrinter] = useState();

  const dateTime = new Date().toLocaleString();

  const TestReceiptData = [
    "\x1B" + "\x40", // init
    "\x1B" + "\x61" + "\x31", // center align
    "\x1B" + "\x21" + "\x30", // em mode on
    `Test Print`,
    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
    "\x0A", //Line Break
    "\x0A", //Line Break
    `${dateTime}` + "\x0A" + "\x0A",
    "\x1D\x68\x64", // Set barcode height (104 dots)
    "\x1D\x77\x02", // Set barcode width (module 2)
    `\x1D\x6B\x45\x0BTESTRECEIPT\x00` + "\x0A",
    `TEST RECEIPT` + "\x0A",
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
    "\x1B" + "\x69", // cut paper (old syntax)
    //"\x1D" + "\x56" + "\x01", // partial cut (new syntax)
    "\x10" + "\x14" + "\x01" + "\x00" + "\x05", // Generate Pulse to kick-out cash drawer**
  ];

  const handleAddPrinterSubmit = async (e) => {
    e.preventDefault();
    let response = undefined;
    if (selectedReceiptPrinter && printerName) {
      response = await api.post("printer-configurations", {
        printerName: printerName,
        printerType: printerSettingsConstants.RECEIPT_PRINTER,
        isDefault: false,
        generalSettings: {
          vendorId: "NoNeed",
          productId: "NoNeed",
          productName: selectedReceiptPrinter.printerName,
          serialNumber: "NoNeed",
          manufacturerName: "NoNeed",
          DeviceType: "NoNeed",
        },
        store: {
          id: selectedStore.id,
          storeName: selectedStore.storeName,
        },
      });
    } else {
      notificationSvc.error(
        !selectedReceiptPrinter
          ? "Please select printer"
          : "Printer Name cannot be empty"
      );
    }

    if (response && response.ok) {
      notificationSvc.success("Printer Settings Saved Successfully");
      hideModal();
    }
  };

  const handleDefaultTestPrint = (e) => {
    e.preventDefault();
    if (selectedPrinter) {
      printTestReceiptData(
        selectedPrinter.generalSettings.productName,
        TestReceiptData
      );
    }
  };

  const handleDefaultPrinter = async (e) => {
    e.preventDefault();
    const storedStore = JSON.parse(
      localStorage.getItem(printerSettingsConstants.SELECTED_STORE)
    );
    const response = await api.put("printer-configurations", {
      id: selectedPrinter.id,
      store: {
        id: storedStore.id,
        storeName: storedStore.storeName,
      },
    });

    if (response && response.ok) {
      sessionStorage.setItem(
        printerSettingsConstants.DEFAULT_PRINTER,
        JSON.stringify(selectedPrinter)
      );
      notificationSvc.success("Default printer set successfully");
      hideModal();
    }
  };

  const onDeviceSelect = (e) => {
    setSelectedPrinter(e);
  };

  const getAvailableZebraPrinters = async () => {
    try {
      const browserPrint = new ZebraBrowserPrintWrapper();
      const defaultZebraPrinter = await browserPrint.getDefaultPrinter();
      if (defaultZebraPrinter) {
        setDefaultZebraPrinter(defaultZebraPrinter);
      }
    } catch (error) {
      notificationSvc.error(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getAvailableZebraPrinters();
    if (allPrinters) {
      const defaultprinter = allPrinters?.find(
        (item) => item.isDefault === true
      );
      console.log("setDefaultPrinter", defaultprinter);
      if (defaultprinter) {
        setSelectedPrinter(defaultprinter);
      }
    }
  }, [allPrinters]);
  const testZebraPrinter = (e) => {
    e.preventDefault();

    try {
      printLabel(
        "^XA^CFA,20^FO155,90^FDCollect Pos^FS^CFA,40^FO110,130^FDTEST PRINT^FS^XZ"
      );
    } catch (error) {
      console.log(error);
      notificationSvc.error(error);
    }
  };

  const onSelectReceiptPrinter = (e) => {
    setSelectedReceiptPrinter(e);
  };

  const handleTestReceiptPrinter = (e) => {
    e.preventDefault();
    printTestReceiptData(selectedReceiptPrinter.printerName, TestReceiptData);
  };

  return (
    <Modal
      className="modal-wrapper printer-settings-modal"
      show={printerSettingModal}
      size="lg"
      onHide={hideModal}
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Printer/Scanner Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="Receipt_Printer"
        >
          <Row>
            <Col sm={3} className="dash-details-sidebar">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="Receipt_Printer">
                    Receipt Printer
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Lable_Printer">Label Printer</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="Receipt_Printer">
                  <Row>
                    <Col xs={12}>
                      <h3>Receipt Printer Settings</h3>
                    </Col>
                    <form onSubmit={(e) => handleAddPrinterSubmit(e)}>
                      <Row></Row>
                      <Row>
                        <Col xs={12}>
                          <h5>Default Printer</h5>
                        </Col>
                        <Col xs={12} className="mb-2 ">
                          <Select
                            placeholder="Select Printer"
                            options={allPrinters ? allPrinters : []}
                            getOptionLabel={(e) =>
                              `${e.printerName} - ${e.generalSettings.productName} `
                            }
                            getOptionValue={(e) => e.id}
                            value={selectedPrinter}
                            onChange={(e) => onDeviceSelect(e)}
                          />
                        </Col>
                        <Col
                          xs={12}
                          className="d-flex justify-content-end align-items-center gap-3"
                        >
                          <button
                            type="button"
                            onClick={(e) => handleDefaultTestPrint(e)}
                            className="btn save-btn"
                          >
                            Test Default Printer
                          </button>

                          <button
                            type="button"
                            onClick={(e) => handleDefaultPrinter(e)}
                            className="btn save-btn"
                            disabled={selectedPrinter?.isDefault === true}
                          >
                            Set Default Printer
                          </button>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <h5>Add New Printer</h5>
                        </Col>
                        <Col xs={12}>
                          <label className="form-label">
                            Available Printers
                          </label>
                        </Col>
                        <Col xs={12} className="mb-2 ">
                          <Select
                            placeholder="Select Printer"
                            options={
                              availableReceiptPrinters
                                ? availableReceiptPrinters
                                : []
                            }
                            getOptionLabel={(e) => e.printerName}
                            getOptionValue={(e) => e.id}
                            value={selectedReceiptPrinter}
                            onChange={(e) => onSelectReceiptPrinter(e)}
                          />
                        </Col>
                        <Col xs={12} className="mb-3">
                          <label className="form-label">
                            Enter Printer Name
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Printer Name"
                            value={printerName}
                            className="form-control"
                            onChange={(e) => setPrinterName(e.target.value)}
                          />
                        </Col>
                        <Col
                          md={12}
                          className="d-flex justify-content-end flex-wrap gap-3 mt-2 mb-2"
                        >
                          <button
                            type="button"
                            onClick={(e) => handleTestReceiptPrinter(e)}
                            className="btn save-btn"
                          >
                            Test Print
                          </button>{" "}
                          <button type="submit" className="btn save-btn">
                            Add Printer
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="Lable_Printer">
                  <Row className="m-2 d-flex flex-column">
                    <Col xs={12} className="my-2">
                      <h3>Label Printer Settings</h3>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} className="mb-3">
                          <label className="form-label">
                            Default Label Printers
                          </label>
                          <input
                            type="text"
                            value={defaultZebraPrinter?.name}
                            className="form-control"
                            disabled
                          />
                        </Col>
                        <Col
                          xs={12}
                          className="mb-3 d-flex justify-content-end gap-3"
                        >
                          <button
                            type="button"
                            onClick={(e) => testZebraPrinter(e)}
                            className="btn save-btn mb-2"
                          >
                            Test Printer
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default PrinterSettings;
