import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./CardPayment.css";
import Select from "react-select";
import SuccessIcon from "../../../../assets/images/Success.svg";
import ErrorIcon from "../../../../assets/images/Error.svg";
import VerifoneIcon from "../../../../assets/images/Verifone.svg";
import { Constants } from "../../../../Constant";

const CardPaymentModal = (props) => {
  const {
    cardPaymentModal,
    toggleCardPaymentModal,
    cancelTransaction,
    cardTransaction,
    cardTransactionStatus,
    setCardTransactionStatus,
    paymentDevices,
    printReceiptOnCardPayment,
    hideModalWithoutPrint,
  } = props;

  const { newSaleConstants } = Constants;
  const hideModal = () => {
    if (cardTransactionStatus === newSaleConstants.SUCCESS) {
      hideModalWithoutPrint();
      return;
    }
    setCardTransactionStatus(newSaleConstants.SELECT_DEVICE);
    setDevice("");
    toggleCardPaymentModal();
  };

  const [device, setDevice] = useState();

  const onDeviceSelect = (e) => {
    setDevice(e);
    setCardTransactionStatus(newSaleConstants.VERIFONE);
    cardTransaction(e.poiId);
  };

  const handleTryAgian = () => {
    setCardTransactionStatus(newSaleConstants.SELECT_DEVICE);
    setDevice("");
  };

  return (
    <Modal
      className="modal-wrapper"
      show={cardPaymentModal}
      size="md"
      onHide={hideModal}
      animation={true}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header
        closeButton={
          cardTransactionStatus === newSaleConstants.VERIFONE ? false : true
        }
      >
        <Modal.Title>Card Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {cardTransactionStatus === newSaleConstants.VERIFONE ? (
            <div className="col-12 p-4 d-flex flex-column justify-content-center align-items-center">
              <h3>Device {device?.serialNumber}</h3>
              <div className="icon-wrapper py-2">
                <img src={VerifoneIcon} className="icon-image" alt="icon" />
              </div>
              <h2 className="text-center pt-2">
                Use Verifone Device to Complete Payment
              </h2>{" "}
              <div className="row d-flex w-100 justify-content-center">
                <div className="col-12 col-md-6">
                  <button
                    type="button"
                    className="btn cancel-button mt-2"
                    onClick={() => cancelTransaction(device.poiId)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ) : cardTransactionStatus === newSaleConstants.SUCCESS ? (
            <div className="col-12 p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="icon-wrapper py-2">
                <img src={SuccessIcon} className="icon-image" alt="icon" />
              </div>
              <h2 className="text-center pt-2">Payment Success</h2>
              <div className="row d-flex justify-content-center w-100">
                <div className="col-12 col-md-6">
                  <button
                    type="button"
                    className="btn submit-button mt-2"
                    onClick={() => printReceiptOnCardPayment()}
                  >
                    Print Receipt
                  </button>
                </div>
              </div>
            </div>
          ) : cardTransactionStatus === newSaleConstants.ERROR ? (
            <div className="col-12 p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="icon-wrapper py-2">
                <img src={ErrorIcon} className="icon-image" alt="icon" />
              </div>
              <h2 className="text-center pt-2">Payment Canceled</h2>
              <div className="row w-100">
                <div className="col-12 col-md-6">
                  <button
                    type="button"
                    className="btn submit-button mt-2"
                    onClick={() => handleTryAgian()}
                  >
                    Try Again
                  </button>
                </div>
                <div className="col-12 col-md-6">
                  <button
                    type="button"
                    className="btn cancel-button mt-2"
                    onClick={hideModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ) : cardTransactionStatus === newSaleConstants.SELECT_DEVICE ? (
            <div className="col-12 p-5 d-flex flex-column justify-content-center text-center">
              <h3>Select Verfione Device To continue payment</h3>

              <Select
                placeholder="Select Device"
                options={paymentDevices ? paymentDevices : []}
                getOptionLabel={(e) => e.serialNumber}
                getOptionValue={(e) => e.poiId}
                value={device}
                onChange={(e) => onDeviceSelect(e)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CardPaymentModal;
