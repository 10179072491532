import { Constants } from "../../../Constant";
import { api } from "../../../Services/api-service";

const { receiptConstants } = Constants;
const sliceItemName = (itemName) => {
  return itemName.length >= receiptConstants.RECEIPT_ITEM_MAX_LENGTH
    ? `${itemName.slice(0, receiptConstants.RECEIPT_ITEM_MAX_LENGTH)}...`
    : itemName +
        receiptConstants.SPACE.repeat(
          receiptConstants.RECEIPT_ITEM_MAX_LENGTH + 3 - itemName.length
        );
};

const prepareItemRow = (itemName, quantity, price) => {
  const formatteditemName = sliceItemName(itemName);
  const formattedString = `${formatteditemName.padEnd(
    receiptConstants.RECEIPT_ITEM_MAX_LENGTH +
      receiptConstants.DOUBLE_SPACE_AFTER_ITEM,
    " "
  )}  ${quantity
    .slice(0, receiptConstants.RECEIPT_QTY_MAX_LENGTH)
    .padEnd(receiptConstants.RECEIPT_QTY_MAX_LENGTH, " ")}  ${price
    .slice(-receiptConstants.RECEIPT_PRICE_MAX_LENGTH)
    .padStart(receiptConstants.RECEIPT_PRICE_MAX_LENGTH, " ")}`;
  const itemRow = formattedString.slice(0, receiptConstants.RECEIPT_ROW_LENGTH);
  return itemRow;
};

const getItemsList = (receiptItems) => {
  let itemsList = receiptItems.map(
    (item) =>
      `${prepareItemRow(
        item.itemName,
        item.quantity.toString(),
        `$${Number(item.price).toFixed(2)}`
      )}\x0A`
  );
  return itemsList;
};

const alignValueLeftAndRight = (leftItem, rightItem) => {
  const lenVal1 = Math.min(leftItem.length, receiptConstants.LEFT_ITEM_LENGTH); // Maximum length for val1
  const lenVal2 = Math.min(
    rightItem.length,
    receiptConstants.RIGHT_ITEM_LENGTH
  ); // Maximum length for val2

  const formattedVal1 = leftItem
    .slice(0, lenVal1)
    .padEnd(receiptConstants.LEFT_ITEM_LENGTH, " ");
  const formattedVal2 = rightItem
    .slice(0, lenVal2)
    .padStart(receiptConstants.RIGHT_ITEM_LENGTH, " ");

  const result = formattedVal1 + "  " + formattedVal2;
  return result.slice(0, receiptConstants.RECEIPT_ROW_LENGTH); // Ensure the total length is 42
};

export const prepareReceiptForPrinter = (
  id,
  type,
  storeDetails,
  selectedCustomer,
  receiptItems,
  discountAmount,
  subTotal,
  taxAmount,
  totalPrice,
  cashTenderedAmount,
  cashOwedAmount
) => {
  const dateTime = new Date().toLocaleString();
  const currency = "$";
  const user = JSON.parse(localStorage.getItem("user"));

  const getTotalItemsQuantity = () => {
    let totalQuantity = 0;
    receiptItems.map((item) => {
      totalQuantity += Number(item.quantity);
    });

    return totalQuantity;
  };

  const receiptData = [
    "\x1B" + "\x40", // init
    "\x1B" + "\x61" + "\x31", // center align
    "\x1B" + "\x21" + "\x30", // em mode on
    //Store Name
    `${storeDetails?.storeName}`,
    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
    "\x0A", //Line Break
    "\x0A", //Line Break
    //Store Address
    `${storeDetails?.phoneNumber}` + "\x0A",
    `${`${storeDetails.line1} ${storeDetails.line2}`}` + "\x0A",
    `${`${storeDetails.city} ${storeDetails.state} ${storeDetails.zip}`}` +
      "\x0A",
    "\x0A", // line break
    "\x1B" + "\x4D" + "\x30", // normal text
    "------------------------------------------" + "\x0A",
    "\x0A", // line break
    "\x1B" + "\x61" + "\x30", // left align
    //Receipt Details
    `Sale ID : ${id}` + "\x0A",
    `Associate : ${`${user.firstName} ${user.lastName}`}` + "\x0A",
    `${
      selectedCustomer?.id
        ? `Customer : ${
            selectedCustomer?.custName
              ? selectedCustomer?.custName
              : selectedCustomer?.name
          }\x0A`
        : ""
    }`,
    `Date : ${dateTime}` + "\x0A",
    `PaymentType: ${type}` + "\x0A",
    "\x0A", // line break
    "------------------------------------------" + "\x0A",
    "\x1B" + "\x45" + "\x0D", // bold on
    "\x1B" + "\x61" + "\x30", // left align
    //Items List
    `${prepareItemRow("Item", "Qty", "Price")}`,
    "\x1B" + "\x45" + "\x0A", // bold off
    "\x0A", // line break
    `${getItemsList(receiptItems).join("")}`, // line break
    "------------------------------------------" + "\x0A",
    "\x0A", // line break
    //Total Items
    `${alignValueLeftAndRight("Total Items :", `${getTotalItemsQuantity()}`)}` +
      "\x0A",
    //Subtotal
    `${alignValueLeftAndRight(
      "Subtotal :",
      `${currency}${Number(subTotal).toFixed(2)}`
    )}` + "\x0A",
    //Tax
    `${alignValueLeftAndRight(
      `Tax(${storeDetails.defaultTax}%) :`,
      `${currency}${Number(taxAmount).toFixed(2)}`
    )}` + "\x0A",
    //Total Amount
    `${alignValueLeftAndRight(
      "Total Amount :",
      `${currency}${Number(totalPrice).toFixed(2)}`
    )}` + "\x0A",
    //Tendered Amount
    `${
      type === "Cash"
        ? `${alignValueLeftAndRight(
            "Tendered Amount :",
            `${currency}${cashTenderedAmount}`
          )}` + "\x0A"
        : ""
    }`,
    //Change Owed
    `${
      type === "Cash"
        ? `${alignValueLeftAndRight(
            "Change Owed :",
            `${currency}${cashOwedAmount}`
          )}` + "\x0A"
        : ""
    }`,
    //Customer Credit used
    `${
      type === "Credit"
        ? `${alignValueLeftAndRight(
            "Customer Credit Used :",
            `$${
              selectedCustomer.credit > 0
                ? subTotal + taxAmount > selectedCustomer?.credit
                  ? Number(selectedCustomer?.credit || 0).toFixed(2)
                  : Number(subTotal - discountAmount).toFixed(2)
                : 0.0
            }`
          )}` + "\x0A"
        : "\x0A"
    }`,
    "------------------------------------------" + "\x0A",
    "\x0A", // line break

    "\x1B" + "\x61" + "\x31", // center align
    "\x1D\x68\x64", // Set barcode height (104 dots)
    "\x1D\x77\x02", // Set barcode width (module 2)
    `\x1D\x6B\x45\x0B${id}\x00`,
    "\x0A", // line break
    `${id}` + "\x0A" + "\x0A",
    //Return Policy
    `${storeDetails?.returnPolicy ? storeDetails.returnPolicy : ""}`,
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
    "\x1B" + "\x69", // cut paper (old syntax)
    //"\x1D" + "\x56" + "\x01", // partial cut (new syntax)
    "\x10" + "\x14" + "\x01" + "\x00" + "\x05", // Generate Pulse to kick-out cash drawer**
  ];

  return receiptData;
};
