import React, { useContext, useMemo, useState, s, useRef } from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import Select from "react-select";
import "./History.css";
import {
  LEFT_ALIGN,
  LEFT_ALIGN_WITH_BORDER,
  datatableStyle,
  paginationStyle,
} from "../../../Utils/Util";
import TableWare from "../../../Components/DataTable/TableWare.tsx";
import { Link } from "react-router-dom";
import { AiFillPrinter } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import moment from "moment";
import { useEffect } from "react";
import { api } from "../../../Services/api-service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StoreContext } from "../../../Components/StoreContext";
import { Modal } from "react-bootstrap";
import Table from "../../../Components/Table/Table";
import { CSVLink } from "react-csv";
import BarcodeReader from "react-barcode-reader";
import notificationSvc from "../../../Services/notification.js";
import { Row, Col } from "react-bootstrap";
import * as XLSX from "xlsx";
import { prepareReceiptForPrinter } from "../ReceipPrinters/prepareReceiptForPrinter.js";
import { printReceiptData } from "../ReceipPrinters/printReceipt.js";

const History = () => {
  const timeOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
    { value: "Custom", label: "Custom" },
  ];
  const { selectedStore } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [json, setJson] = useState([]);
  const [cogs, setCogs] = useState(0);
  const [price, setPrice] = useState(0);
  const [orderBy, setOrderBy] = useState(null);
  const [orderByDir, setOrderByDir] = useState(null);
  const [selectedTransaction, setSelected] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchString, setSearchString] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [option, setOption] = useState({ label: "All", value: "All" });
  const [timeFilter, setTimeFilter] = useState("Daily");
  const [timeFrame, setTimeFrame] = useState(new Date());
  const [dateRange, setRange] = useState([]);
  const [month, setMonth] = useState(moment().format("MMMM"));
  const [year, setYear] = useState("2023");
  const [isImportModal, setIsImportModal] = useState(false);
  const [importDuplicateRecords, setImportDuplicateRecords] = useState([]);
  const importFileRef = useRef();
  const [storeDetails, setStoreDetails] = useState([]);

  const searchItem = (val) => {
    setPageIndex(1);
    setSearchString(val);
  };

  useEffect(() => {
    if (selectedStore) {
      const start = moment(new Date()).startOf("day").toISOString();
      const end = moment(new Date()).endOf("day").toISOString();
      getHistory("All", start, end);
      getStoreDetails();
      localStorage.setItem("selectedStore", JSON.stringify(selectedStore));
    }
  }, [selectedStore]);

  const getDaily = (date, value = option.value) => {
    const start = moment(new Date(date)).startOf("day").toISOString();
    const end = moment(new Date(date)).endOf("day").toISOString();
    getHistory(value, start, end);
  };

  const getMonthly = (date, value = option.value) => {
    const start = moment().month(date).startOf("month").toISOString();
    const end = moment().month(date).endOf("month").toISOString();
    getHistory(value, start, end);
  };
  const getYearly = (date, value = option.value) => {
    const start = moment().year(date).startOf("year").toISOString();
    const end = moment().year(date).endOf("year").toISOString();
    getHistory(value, start, end);
  };

  const getRange = async (e, value = option.value) => {
    setRange(e);
    if (e.length > 1 && e[1]) {
      const start = moment(new Date(e[0])).startOf("day").toISOString();
      const end = moment(new Date(e[1])).endOf("day").toISOString();
      getHistory(value, start, end);
    }
  };

  const getStoreDetails = async () => {
    if (selectedStore?.id) {
      const response = await api.get(`store/${selectedStore.id}`);
      setStoreDetails(response.data.data);
    }
  };

  const getHistory = async (type, startDate, endDate) => {
    if (selectedStore?.value) {
      const response = await api.get(
        `get-transaction/${selectedStore.value}?type=${type}&startDate=${startDate}&endDate=${endDate}`
      );
      if (response.ok) {
        setJson(response.data.data);
      }
    }
  };

  const filterOption = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Transaction",
      label: "Sale",
    },
    {
      value: "Return",
      label: "Return",
    },
    {
      value: "Trade",
      label: "Trade",
    },
  ];

  const handleFilterMenuChange = async (e) => {
    if (timeFilter === "Daily") {
      getDaily(timeFrame, e);
    }
    if (timeFilter === "Monthly") {
      getMonthly(month, e);
    }
    if (timeFilter === "Yearly") {
      getYearly(year, e);
    }
    if (timeFilter === "Custom") {
      getRange([new Date(), new Date()], e);
    }
  };

  const handleChangeTimeFilter = (selectedOption) => {
    setTimeFilter(selectedOption.value);
    if (selectedOption.value === "Daily") {
      getDaily(timeFrame);
    }
    if (selectedOption.value === "Monthly") {
      getMonthly(month);
    }
    if (selectedOption.value === "Yearly") {
      getYearly(year);
    }
    if (selectedOption.value === "Custom") {
      getRange([new Date(), new Date()]);
    }
  };

  let result = useMemo(() => {
    if (!searchString) return json;
    return json.filter((js) =>
      JSON.stringify(js).toLowerCase().includes(searchString.toLowerCase())
    );
  }, [searchString, json]);

  const purchasedItem = [
    {
      Header: "Item",
      accessor: "item",
      Cell: ({ row }) =>
        selectedTransaction.type !== "Trade"
          ? row.original.item
          : row.original.product_name,
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ row }) =>
        selectedTransaction.type !== "Trade"
          ? row.original.price
          : row.original.price.unit_sell_price,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      Cell: ({ row }) =>
        selectedTransaction.type !== "Trade"
          ? row.original.quantity
          : row.original.price.quantity,
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: (props) =>
        selectedTransaction.type === "Trade"
          ? Number(props.row.original.price.unit_sell_price) *
            Number(props.row.original.price.quantity)
          : Number(props.row.original.price) *
            Number(props.row.original.quantity).toFixed(2),
    },
  ];

  useEffect(() => {
    let sum = 0;
    let multiple = 0;
    result.forEach((rs) => {
      sum += rs.inventory.reduce(
        (a, b) =>
          a +
          Number(b.price.unit_purchase_price || b.cogs || 0) *
            Number(b.price.quantity || b.quantity || 0),
        0
      );
      multiple += rs.inventory.reduce(
        (a, b) =>
          a +
          Number(b.price.unit_sell_price || b.price) *
            Number(b.price.quantity || b.quantity),
        0
      );
    });
    setCogs(sum);
    setPrice(multiple);
  }, [result]);

  const printHistoryReceipt = (row) => {
    if (row.type === "Trade" || row.type === "Return") {
      notificationSvc.error("Trade or Return Cannot be Print");
      return;
    }
    const receiptData = prepareReceiptForPrinter(
      row.id,
      row.PaymentType,
      storeDetails,
      row.customer,
      row.inventory,
      row.discount,
      row.SubTotal,
      row.Tax,
      row.TotalAmountPaid,
      "0.00",
      "0.00"
    );
    printReceiptData(receiptData);
  };

  const transHist = [
    {
      id: "Sale ID",
      name: "Sale ID",
      selector: (row) => row.id,
      sortable: true,
      style: LEFT_ALIGN_WITH_BORDER,
    },
    {
      id: "Created On",
      name: "Created On",
      selector: (row) => moment(new Date(row.createdOn)).format("MM-DD-YYYY"),
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Location",
      name: "Location",
      selector: (row) => row.store?.name,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Transaction Type",
      name: "Transaction Type",
      selector: (row) => row.type,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Payment Type",
      name: "Payment Type",
      selector: (row) => row.PaymentType,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Cart Qty",
      name: "Cart Qty",
      selector: (row) =>
        row.inventory?.reduce((a, b) => a + Number(b.quantity), 0) ||
        row.inventory?.reduce((a, b) => a + Number(b.price.quantity), 0) ||
        0,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Customer",
      name: "Customer",
      selector: (row) => row.customer?.name,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      id: "Amount",
      name: "Amount",
      selector: (row) => Number(row.TotalAmountPaid).toFixed(2),
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      cell: (row) => {
        return (
          <>
            <div className="action-icons-edit-del">
              <span>
                <Link
                  to="#"
                  onClick={() => {
                    setSelected(row);
                    setShow(true);
                  }}
                >
                  <GrTransaction />
                </Link>
              </span>
              &nbsp;&nbsp;
              <span>
                <Link
                  onClick={() => {
                    printHistoryReceipt(row);
                  }}
                >
                  <AiFillPrinter />
                </Link>
              </span>
            </div>
          </>
        );
      },
      name: "Action",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const importTransColumn = [
    {
      id: "Number",
      name: "Number",
      selector: (row) => row.fileImportRecordId,
      sortable: true,
      style: LEFT_ALIGN_WITH_BORDER,
    },
    {
      id: "Type",
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      style: LEFT_ALIGN_WITH_BORDER,
    },
  ];

  const handleScan = (data) => {
    setSearchString(data);
    searchItem(data);
  };

  const handleError = (err) => {};
  const handleFileUpload = async (e) => {
    let errors = [];
    let transactionHistory = [];
    let required = [
      "Number",
      "Type",
      "Date",
      "Grand Total",
      "Cart QTY",
      "Pay Type",
      "Customer",
      "Store",
    ];

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1, FS: "`" });
      const dataStringLines = data.split(/\r\n|\n/);
      const headers = dataStringLines[0].split("`");
      required.forEach((key) => {
        if (!headers.includes(key)) {
          errors.push(`${key} is not found in file`);
        }
      });
      if (errors.length > 0) {
        notificationSvc.error(errors[0]);
        e.target.value = null;
        return;
      }
      for (let i = 1; i < dataStringLines.length; i++) {
        let item = dataStringLines[i].split("`");
        transactionHistory.push({
          fileImportRecordId: item[headers.indexOf("Number")],
          type: item[headers.indexOf("Type")],
          date: item[headers.indexOf("Date")],
          grandTotal: item[headers.indexOf("Grand Total")],
          cartQty: item[headers.indexOf("Cart QTY")],
          payType: item[headers.indexOf("Pay Type")],
          customer: item[headers.indexOf("Customer")],
          store: {
            id: selectedStore?.value,
            name: selectedStore?.label,
          },
        });
      }
      // handle duplicate records in file
      let valueArr = transactionHistory.map(
        (record) => record.fileImportRecordId
      );
      let duplicateRecords = [];
      valueArr.forEach((value, index) => {
        valueArr.lastIndexOf(value) !== index && duplicateRecords.push(index);
      });

      if (duplicateRecords.length) {
        setImportDuplicateRecords(
          duplicateRecords.map((duplicateIndex) => {
            return transactionHistory[duplicateIndex];
          })
        );
        setIsImportModal(true);
        notificationSvc.error(
          "File cannot be imported because of duplicate records, Number must be unique"
        );
        e.target.value = null;
        return;
      }

      const response = await api.post(
        `bulk-upload-transaction-history/${selectedStore?.value}`,
        transactionHistory
      );
      if (response.ok) {
        notificationSvc.success(response.data.message);
        if (response.data.data.length) {
          setImportDuplicateRecords(response.data.data);
          setIsImportModal(true);
        }
        getHistory();
      }
      e.target.value = null;
    };
    reader?.readAsBinaryString(file);
  };

  return (
    <>
      {" "}
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <main>
        <div className="container-fluid">
          <div className="row page-header mt-3">
            <div className="col-6">
              <h2 className="heading-main-page">Transaction History</h2>
            </div>
            <form
              onSubmit={(e) => e.preventDefault()}
              className="fileBtn upload-btn book-details-btn col-6 d-flex"
            >
              <label
                htmlFor="file"
                ref={importFileRef}
                className="btn btn-indigo p-0 ms-auto"
              >
                <button
                  className="btn"
                  type="submit"
                  onClick={() => {
                    importFileRef.current.click();
                  }}
                >
                  Import from file
                </button>
              </label>
              <input
                type="file"
                onChange={(e) => handleFileUpload(e)}
                className="fileUpload"
                id="file"
                required
                hidden
              />
            </form>
          </div>

          <div className="row filter-transaction-history mt-3">
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <Select
                options={timeOptions}
                value={{ label: timeFilter, value: timeFilter }}
                onChange={(e) => handleChangeTimeFilter(e)}
                placeholder="Time Filter"
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
              {timeFilter === "Daily" ? (
                <DatePicker
                  className="filter-date form-control"
                  selected={timeFrame}
                  showMonthDropdown
                  placeholderText="Select Date"
                  onChange={(e) => {
                    getDaily(e);
                    setTimeFrame(e);
                  }}
                />
              ) : timeFilter === "Custom" ? (
                <DatePicker
                  className="filter-date form-control"
                  selectsRange={true}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  value={dateRange}
                  // selected={dateRange}
                  showMonthDropdown
                  placeholderText="Select Date"
                  onChange={(e) => getRange(e)}
                />
              ) : timeFilter === "Monthly" ? (
                <Select
                  options={[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ].map((op) => {
                    return { label: op, value: op };
                  })}
                  value={{ label: month, value: month }}
                  onChange={(e) => {
                    getMonthly(e.value);
                    setMonth(e.value);
                  }}
                  placeholder="Select Category"
                />
              ) : (
                <Select
                  options={[
                    "2030",
                    "2029",
                    "2028",
                    "2027",
                    "2026",
                    "2025",
                    "2024",
                    "2023",
                    "2022",
                    "2021",
                    "2020",
                    "2019",
                    "2018",
                    "2017",
                    "2016",
                    "2015",
                  ].map((op) => {
                    return { label: op, value: op };
                  })}
                  value={{ label: year, value: year }}
                  onChange={(e) => {
                    getYearly(e.value);
                    setYear(e.value);
                  }}
                  placeholder="Select Year"
                />
              )}
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <Select
                placeholder="Filter"
                options={filterOption}
                value={option}
                onChange={(e) => {
                  handleFilterMenuChange(e.value);
                  setOption(e);
                }}
              />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <input
                type="text"
                className="form-control"
                value={searchString}
                onChange={(e) => searchItem(e.target.value)}
                placeholder="Search here"
              />
            </div>
          </div>
          <div className="transac-hist-data mt-3">
            <div className="table-responsive">
              <TableWare
                json={result}
                tableColumns={transHist}
                customStyles={datatableStyle}
                paginationStyles={paginationStyle}
                enableSearch={false}
                loading={loading}
                enablePagination={totalCount > pageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalCount={totalCount}
                cogs={cogs}
                price={price}
                // onSearchChange={(str: any) => {
                //   setPageIndex(1);
                //   setSearchString(str);
                // }}
                onSortChange={(orderBy, orderByDir) => {
                  setPageIndex(1);
                  setOrderBy(orderBy);
                  setOrderByDir(orderByDir);
                }}
                onPageChange={setPageIndex}
              />
              <div>Total</div>
              <div>COGS: {Number(cogs).toFixed(2)}</div>
              <div>Sell: {Number(price).toFixed(2)}</div>
              <div>Net: {Number(price - cogs).toFixed(2)}</div>
            </div>
          </div>
        </div>
      </main>
      {/* receipt modal */}
      <Modal
        size="lg"
        show={show}
        centered
        onHide={() => setShow(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTransaction?.inventory && (
            <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 col-xxl-12 receipt-main">
              <div className="receipt-new-sale-main">
                <div className="recepit-item-purchased">
                  <Table
                    headers={purchasedItem}
                    gridData={selectedTransaction.inventory}
                    pageSize={10000}
                  />
                </div>

                <div className="sales-actions">
                  <div className="row">
                    <div className="col-6">
                      <p className="discount">
                        Sub Total(
                        {selectedTransaction.inventory.reduce(
                          (a, b) => a + Number(b.quantity || b.price.quantity),
                          0
                        ) || 0}{" "}
                        Item(s))
                      </p>
                    </div>
                    <div className="col-6 ">
                      <p className="text-end">
                        $ {Number(selectedTransaction.SubTotal).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="discount">Discount</p>
                    </div>
                    <div className="col-6 ">
                      <p className="text-end">
                        ${" "}
                        {selectedTransaction.discount
                          ? Number(selectedTransaction.discount).toFixed(2)
                          : 0.0}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <p className="discount">{`Tax(${Number(
                        (selectedTransaction.Tax > 0
                          ? selectedTransaction.Tax /
                            selectedTransaction.SubTotal
                          : 0) * 100
                      ).toFixed(2)}%)`}</p>
                    </div>
                    <div className="col-6 text-right">
                      <p className="text-end">
                        ${" "}
                        {Number(
                          (selectedTransaction.Tax > 0
                            ? selectedTransaction.Tax /
                              selectedTransaction.SubTotal
                            : 0) * selectedTransaction.SubTotal
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>

                  {selectedTransaction.creditUsed > 0 && (
                    <div className="row">
                      <div className="col-6">
                        <p className="discount">Credit Used</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="text-end">
                          $ {Number(selectedTransaction.creditUsed).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-6">
                      <p className="discount">Total</p>
                    </div>
                    <div className="col-6">
                      <p className="text-end">
                        ${" "}
                        {Number(selectedTransaction.TotalAmountPaid).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* duplicate records modal */}
      <Modal
        show={isImportModal}
        size="lg"
        onHide={() => setIsImportModal(!isImportModal)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableWare
            json={importDuplicateRecords}
            tableColumns={importTransColumn}
            customStyles={datatableStyle}
            enablePagination={false}
            enableSearch={false}
            sortServer={false}
            loading={loading}
            onSortChange={(orderBy, orderByDir) => {
              setPageIndex(1);
              setOrderBy(orderBy);
              setOrderByDir(orderByDir);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default History;
