import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { Constants } from "../../../Constant";
import notificationSvc from "../../../Services/notification";

const { inventoryConstants } = Constants;

export const printZebraLabel = (type, selectedRowsForLabel) => {
  let zpl;
  const itemCondition =
    selectedRowsForLabel.price.type === "New" ? "* NEW *" : "* PRE-OWNED *";
  const itemName =
    selectedRowsForLabel.product_name.length > 15
      ? `${selectedRowsForLabel.product_name.slice(0, 15)}...`
      : selectedRowsForLabel.product_name;
  const itemCategory = selectedRowsForLabel.category_name;
  const itemPrice = `$${Number(
    selectedRowsForLabel.price.unit_sell_price
  ).toFixed(2)}`;
  const itemSku = selectedRowsForLabel.sku;

  // ZPL script to print a simple barcode
  if (type === inventoryConstants.MEDIA_LABEL) {
    zpl = `^XA
          ^BY1,1,100
          ^CF0,20
          ^FO20,20^FD${itemName}^FS
          ^CFA,20
          ^FO20,50^FD[${itemCategory}]^FS
          ^CFA,20
          ^FO20,90^FD${itemCondition}^FS
          ^CFA,20
          ^FO300,90^FD${itemPrice}^FS
          ^FO120,125^BC^FD${itemSku}^FS
          ^XZ`;
  } else if (type === inventoryConstants.BOX_LABEL) {
    zpl = `^XA
          ^BY1,1,100
          ^CF0,20
          ^FO20,20^FD${itemName}^FS
          ^CFA,20
          ^FO20,50^FD[${itemCategory}]^FS
          ^CFA,10
          ^FO20,90^FD[${itemSku}]^FS
          ^CFA,32
          ^FO90,125^FD${itemCondition}^FS
          ^CFA,25
          ^FO20,200^FD${itemPrice}^FS
          ^XZ`;
  }
  console.log("zpl", zpl);
  printLabel(zpl);
};

export const printLabel = async (zpl) => {
  try {
    // Create a new instance of the object
    const browserPrint = new ZebraBrowserPrintWrapper();

    // Select default printer
    const defaultPrinter = await browserPrint.getDefaultPrinter();

    // Set the printer
    browserPrint.setPrinter(defaultPrinter);
    browserPrint.print(zpl);
  } catch (error) {
    console.log(error);
    notificationSvc.error(error);
  }
};
