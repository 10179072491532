export const receiptConstants = {
  RECEIPT_ITEM_MAX_LENGTH: 22,
  SPACE: " ",
  DOUBLE_SPACE_AFTER_ITEM: 2,
  SINGLE_SPACE_AFTER_ITEM: 1,
  RECEIPT_PRICE_MAX_LENGTH: 9,
  RECEIPT_QTY_MAX_LENGTH: 4,
  RECEIPT_ROW_LENGTH: 42,
  LEFT_ITEM_LENGTH: 20,
  RIGHT_ITEM_LENGTH: 20,
};
