export const ITEM_OPTIONS = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "completeInBoxPrice",
    label: "Complete In Box",
  },
  {
    value: "loosePrice",
    label: "Loose",
  },
];
