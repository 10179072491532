import { newSaleConstants } from "./newSale.constant";
import { inventoryConstants } from "./inventory.constant";
import { printerSettingsConstants } from "./printerSettings.constant";
import { receiptConstants } from "./receipt.constant";
import { customerConstants } from "./customers.constant";

export const Constants = {
  newSaleConstants,
  printerSettingsConstants,
  inventoryConstants,
  receiptConstants,
  ...customerConstants,
};
