import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BarcodeReader from "react-barcode-reader";
import notificationSvc from "../../../Services/notification";

const SearchInvestory = (props) => {
  const navigate = useNavigate();
  const [scanData, setScanData] = useState("Search inventory...");

  const loadOptions = (inputValue, callback) => {
    getInventList(inputValue).then((options) => {
      const selectOptions = options.map((option) => ({
        value: option.id,
        label: (
          <div className="invent-display invent-add-display">
            <h5 className="category">{option.consoleName} </h5>
            &nbsp;
            <h5 className="product pt-2">{option.productName}</h5>
          </div>
        ),
      }));
      callback(selectOptions);
    });
  };

  const getInventList = async (query) => {
    try {
      const response = await axios.get(
        `https://www.pricecharting.com/api/products?t=be026bb3efdf1c0891fc044277d53e2f46a5ab45&q=${query}`
      );
      if (response.status === 200) {
        const products = response.data.products.map((product) => ({
          id: product.id,
          consoleName: product["console-name"],
          productName: product["product-name"],
        }));
        return products;
      }
    } catch (e) {
      console.log("pricecharting error while fething product", e);
    }
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 4,
      borderColor: state.isFocused ? "#cccccc;" : "#cccccc",
      boxShadow: state.isFocused ? "0 0 0 1px blue" : "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#283f68;;" : "white",
      color: state.isFocused ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "black",
    }),
    input: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "black",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "gray",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "white" : "gray",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "gray",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "blue" : "white",
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "black",
    }),
    optionGroup: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "blue" : "white",
      color: state.isFocused ? "white" : "black",
    }),
    group: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "white" : "gray",
    }),
    label: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "black",
    }),
  };

  const getData = (id) => {
    console.log(id);
    navigate(`/inventory-options/${id}`);
    props.setId(id);
    props.productInfo();
  };

  const handleScan = (data) => {
    if (props.asyncSelectRef.current) {
      props.asyncSelectRef.current.clearValue();
    }
    setScanData(data);

    getInventList(data).then((options) => {
      if (options.length > 0) {
        options.map((item) => getData(item.id));
      } else {
        notificationSvc.error("Barcode doesn’t exist in Inventory");
      }
    });
  };

  const handleError = (error) => {};

  const handleItemSelect = (product) => {
    product?.value && getData(product.value);
  };
  return (
    <>
      <div>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <AsyncSelect
        ref={props.asyncSelectRef}
        cacheOptions
        loadOptions={loadOptions}
        placeholder={scanData}
        onChange={(selectedOption) => handleItemSelect(selectedOption)}
        styles={styles}
      />
    </>
  );
};

export default SearchInvestory;
