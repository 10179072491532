export const newSaleConstants = {
  CREDIT_CARD: "CreditCard",
  CASH: "Cash",
  CREDIT: "Credit",
  VERIFONE: "Verifone",
  SUCCESS: "Success",
  SELECT_DEVICE: "select_Device",
  ERROR: "Error",
  PROCESSED: "Processed",
  DRAFT: "Draft",
};
