import React, { useContext, useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import AsyncSelect from "react-select/async";
import { api } from "../../../Services/api-service";
import { AiFillDelete, AiOutlineEdit, AiFillPrinter } from "react-icons/ai";
import notificationSvc from "../../../Services/notification";
import "./Items.css";
import {
  LEFT_ALIGN,
  LEFT_ALIGN_WITH_BORDER,
  datatableStyle,
  paginationStyle,
} from "../../../Utils/Util";
import Table from "../../../Components/Table/Table";
import { StoreContext } from "../../../Components/StoreContext";
import { CSVLink } from "react-csv";
import { printZebraLabel } from "../zebraPrinter/zebraPrinter";
import { Constants } from "../../../Constant";

const { inventoryConstants } = Constants;

const Items = () => {
  const { selectedStore } = useContext(StoreContext);
  const [key, setKey] = useState();
  const [inventoryList, setInventoryList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [orderByDir, setOrderByDir] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [labelModal, setLabelModal] = useState(false);
  const [labelPrintOption, setLabelPrintOption] = useState();

  const [searchString, setSearchString] = useState("");

  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowsForLabel, setSelectedRowsForLabel] = useState([]);

  const navigate = useNavigate();

  const toggleLabelModal = () => {
    setLabelModal(!labelModal);
  };

  const printLabels = (row) => {
    setLabelPrintOption(inventoryConstants.SINGLE);
    setSelectedRowsForLabel(row);
    toggleLabelModal();
  };

  const printBoxLabel = () => {
    if (labelPrintOption === inventoryConstants.SINGLE) {
      printZebraLabel(inventoryConstants.BOX_LABEL, selectedRowsForLabel);
    } else {
      selectedRowsForLabel.map((item) => {
        printZebraLabel(inventoryConstants.BOX_LABEL, item.original);
      });
    }
    toggleLabelModal();
  };
  const printMediaLabel = () => {
    if (labelPrintOption === inventoryConstants.SINGLE) {
      printZebraLabel(inventoryConstants.MEDIA_LABEL, selectedRowsForLabel);
    } else {
      selectedRowsForLabel.map((item) => {
        printZebraLabel(inventoryConstants.MEDIA_LABEL, item.original);
      });
    }
    toggleLabelModal();
  };

  const InventoryColumns = [
    {
      Header: "sku",
      accessor: "sku",
      selector: (row) => row.sku,
      sortable: true,
      width: "8%",
      style: LEFT_ALIGN_WITH_BORDER,
    },
    {
      Header: "Date Added",
      accessor: "date_added",
      selector: (row) => row.date_added,
      sortable: true,
      width: "11%",
      style: LEFT_ALIGN,
    },
    {
      Header: "Category Name",
      accessor: "category_name",
      selector: (row) => row.category_name,
      sortable: true,
      width: "13%",
      style: LEFT_ALIGN,
    },
    {
      Header: "Product Name",
      accessor: "product_name",
      selector: (row) => row.product_name,
      sortable: true,
      style: LEFT_ALIGN,
    },

    {
      Header: "Unit Purchase Price",
      accessor: "price.unit_purchase_price",
      selector: (row) => `$ ${row.price.unit_purchase_price}`,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      Header: "Unit Sell Price",
      accessor: "price.unit_sell_price",
      selector: (row) => `$ ${row.price.unit_sell_price}`,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      Header: "Quantity",
      accessor: "price.quantity",
      selector: (row) => row.price.quantity,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      Header: "Condition",
      accessor: "price.type",
      selector: (row) => row.price.type,
      sortable: true,
      style: LEFT_ALIGN,
    },
    {
      Header: "Action",
      accessor: "riw",
      Cell: ({ row }) => {
        return (
          <>
            <div className="action-icons-edit-del d-flex">
              <span>
                <AiOutlineEdit
                  onClick={() => navigate(`/edit-inventory/${row.original.id}`)}
                />
              </span>
              &nbsp;
              <span>
                <AiFillDelete
                  onClick={() => deleteInventItem(row.original.id)}
                />
              </span>
              &nbsp;
              <span>
                <AiFillPrinter onClick={() => printLabels(row.original)} />
              </span>
            </div>
          </>
        );
      },
      name: "Action",
      // width: "8%",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (selectedStore?.value) {
      getInventory();
    }
  }, [selectedStore]);

  const getInventory = async () => {
    const response = await api.get(`inventory?store=${selectedStore.value}`);
    if (response && response.ok) {
      console.log(response.data);
      setInventoryList(
        response.data.data
          .filter((item) => item.price.quantity > 0)
          .map((item) => {
            return {
              ...item,
              price: {
                ...item.price,
                unit_purchase_price: Number(
                  item.price.unit_purchase_price
                ).toFixed(2),
                unit_sell_price: Number(item.price.unit_sell_price).toFixed(2),
                quantity: parseInt(item.price.quantity, 10),
              },
            };
          })
      );
    }
  };

  const deleteInventItem = async (id) => {
    const response = await api.delete(`inventory/${id}`, {
      store: selectedStore.value,
      id: id,
    });
    if (response && response.ok) {
      notificationSvc.success("Item Deleted Successfully.");

      getInventory();
    }
  };

  const formatDate = (date) => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  let headers = [
    {
      label: "Stock #",
      key: "sku",
    },
    { label: "Condition", key: "price.type" },
    { label: "Product Name", key: "product_name" },
    { label: "Category", key: "category_name" },
    { label: "COGS", key: "price.unit_purchase_price" },
    { label: "Price", key: "price.unit_sell_price" },
    { label: "Store", key: "store.name" },
    { label: "Quantity", key: "price.quantity" },
  ];

  const handleFileUpload = async (e) => {
    let errors = [];
    let inventory = [];
    let required = [
      "Stock #",
      "Condition",
      "Product Name",
      "Category",
      "COGS",
      "Price",
      "Store",
      "Quantity",
    ];

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const dataStringLines = data.split(/\r\n|\n/);
      const headers = dataStringLines[0].split(",");
      required.forEach((key) => {
        if (!headers.includes(key)) {
          errors.push(`${key} is not found in file`);
        }
      });
      if (errors.length > 0) {
        notificationSvc.error(errors[0]);
        e.target.value = null;
        return;
      }
      for (let i = 1; i < dataStringLines.length; i++) {
        let item = dataStringLines[i].split(",");
        inventory.push({
          product_id: Math.floor(Math.random() * 100000).toString(),
          product_name: item[headers.indexOf("Product Name")],
          category_name: item[headers.indexOf("Category")],
          sku: item[headers.indexOf("Stock #")],
          store: {
            id: selectedStore.value,
            name: selectedStore.label,
          },
          date_added: formatDate(new Date()),
          price: {
            unit_purchase_price: Number(item[headers.indexOf("COGS")]) || 0,
            unit_sell_price: Number(item[headers.indexOf("Price")]) || 0,
            quantity: Number(item[headers.indexOf("Quantity")]),
            type:
              item[headers.indexOf("Condition")] === "completeInBox"
                ? "Complete In Box"
                : item[headers.indexOf("Condition")] === "loose"
                ? "Loose"
                : "New",
          },
        });
      }
      const response = await api.post("bulk-upload", inventory);
      if (response.ok) {
        notificationSvc.success("Data bulk uploaded successfully");
        getInventory();
      }
      e.target.value = null;
    };
    reader?.readAsBinaryString(file);
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row page-header mt-3">
          <div className="col-12">
            <h2 className="heading-main-page">Inventory Details</h2>
          </div>
          <Col md={7} className="book-details-btn d-flex gap-3">
            <div className="fileBtn upload-btn me-2">
              <input
                type="file"
                onChange={(e) => handleFileUpload(e)}
                className="fileUpload"
                id="file"
              />
              <label htmlFor="file" className="btn btn-indigo px-3">
                <button className="btn">Import from file</button>
              </label>
            </div>
          </Col>
          <Col
            md={5}
            className="book-details-btn d-flex flex-wrap gap-2 justify-content-end align-content-center"
          >
            <button
              className="btn"
              onClick={() => {
                navigate("/inventory-options/add-inventory");
              }}
            >
              Add Inventory
            </button>

            <CSVLink
              headers={headers}
              data={inventoryList}
              filename="inventory_items.csv"
              className="btn"
            >
              Export CSV
            </CSVLink>
          </Col>
        </div>
        <div className="inventory-item-table mt-3">
          <div className="table-responsive">
            <Table
              gridData={inventoryList}
              headers={InventoryColumns}
              customStyles={datatableStyle}
              paginationStyles={paginationStyle}
              search={true}
              loading={loading}
              enablePagination={totalCount > pageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalCount={totalCount}
              // onSearchChange={(str: any) => {
              //   setPageIndex(1);
              //   setSearchString(str);
              // }}
              onSortChange={(orderBy, orderByDir) => {
                setPageIndex(1);
                setOrderBy(orderBy);
                setOrderByDir(orderByDir);
              }}
              onPageChange={setPageIndex}
              checked
              setSelectedRowsForLabel={setSelectedRowsForLabel}
              toggleLabelModal={toggleLabelModal}
              setLabelPrintOption={setLabelPrintOption}
            />
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={labelModal}
        onHide={toggleLabelModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Print Box/Media Label
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="py-3">
            <Col xs={12}>
              <h3>Select Label Type</h3>
              {selectedRowsForLabel?.length > 0 && (
                <p>Total Items Selected : {selectedRowsForLabel?.length}</p>
              )}
            </Col>
            <Col className="d-flex flex-wrap gap-3 mt-2">
              <button
                className="btn print-button"
                onClick={() => {
                  printBoxLabel();
                }}
              >
                Print Box Label
              </button>{" "}
              <button
                className="btn print-button "
                onClick={() => {
                  printMediaLabel();
                }}
              >
                Print Media Label
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Items;
