import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import Select from "react-select";
import { StoreContext } from "../StoreContext";
import { api } from "../../Services/api-service";
import authSvc from "../../Services/auth-service";
import { filter, skip } from "rxjs";
import { AiOutlineMenu } from "react-icons/ai";
import PrinterSettings from "../PrinterSettings/PrinterSettings.jsx";
import {
  connectToQZTray,
  disconnectFromQZTray,
} from "../../Pages/Transactions/ReceipPrinters/printReceipt.js";
import qz from "qz-tray";
import { printerSettingsConstants } from "../../Constant/printerSettings.constant.js";

const Navbar = () => {
  const { stores, selectedStore, setSelectedStore, setStores } =
    useContext(StoreContext);

  const [printerSettingModal, setPrinterSettingModal] = useState(false);
  const [allPrinters, setAllPrinters] = useState([]);
  const [availableReceiptPrinters, setAvailableReceiptPrinters] = useState([
    111,
  ]);

  const togglePrinterSettingModal = () => {
    setPrinterSettingModal(!printerSettingModal);
  };

  const getAllReceiptPrinters = () => {
    connectToQZTray().then(async () => {
      await qz.printers
        .find()
        .then((printers) => {
          const printerList = printers.map((printer, index) => {
            return { id: index, printerName: printer };
          });
          setAvailableReceiptPrinters(printerList);
          console.log("Available printers:", printerList);
          disconnectFromQZTray();
        })
        .catch((error) => {
          console.log("Getting All Printers Error : ", error);
        });
    });
  };

  const getAllPrinters = async () => {
    const response = await api.get(
      `printer-configurations/?storeId=${selectedStore.id}`
    );
    if (response && response.ok) {
      setAllPrinters(response.data.data);
    }
  };

  const openPrinterSettingModal = async (e) => {
    e.preventDefault();
    localStorage.setItem("selectedStore", JSON.stringify(selectedStore));
    getAllPrinters();
    getAllReceiptPrinters();
    togglePrinterSettingModal();
  };

  const storeOptions =
    stores.length > 0
      ? stores.map((store) => ({
          ...store,
          value: store.id,
          label: store.storeName,
        }))
      : [];

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    const response = await api.get("get-store");

    if (response && response.status === 200) {
      setStores(response.data.data);
      if (!selectedStore && response.data.data.length > 0) {
        setSelectedStore({
          ...response.data.data[0],
          value: response.data.data[0].id,
          label: response.data.data[0].storeName,
        });
      }
    }
  };

  useEffect(() => {
    const subscription = authSvc.loginObservable$
      .pipe(
        skip(1),
        filter((isLoggedIn) => !isLoggedIn)
      )
      .subscribe(() => {
        localStorage.clear();
        window.location.reload();
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleStoreChange = (selectedOption) => {
    sessionStorage.removeItem(printerSettingsConstants.DEFAULT_PRINTER);
    setSelectedStore(selectedOption);
    localStorage.setItem("selectedStore", JSON.stringify(selectedOption));
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary pos-navbar fixed-top">
        <div className="container-fluid">
          {/* <button
          className="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions">
          <AiOutlineMenu color="#ffff" size={30} />
        </button> */}
          <a
            className="navbar-brand"
            href="#"
            style={{
              fontFamily: "Josefin-bold",
              color: "#ffff",
              fontSize: "30px",
            }}
          >
            POS
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
              <Link to="/" className="nav-link px-3">
                <span>Dashboard</span>
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Transaction
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/new-sale" className="dropdown-item">
                    <span>New Sale</span>
                  </Link>
                </li>
                <li>
                  <Link to="/new-Trade" className="dropdown-item">
                    <span>New Trade</span>
                  </Link>
                </li>
                <li>
                  <Link to="/new-return" className="dropdown-item">
                    <span>New Return</span>
                  </Link>
                </li>
                <li>
                  <Link to="/transaction-history" className="dropdown-item">
                    <span>Transaction History</span>
                  </Link>
                </li>
                <li>
                  <Link to="/transaction-drafts" className="dropdown-item">
                    <span>Drafts</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Inventory
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/inventory-items" className="dropdown-item">
                    <span>Items</span>
                  </Link>
                </li>
                <li>
                  <Link to="/inventory-history" className="dropdown-item">
                    <span>History</span>
                  </Link>
                </li>
                <li>
                  <Link to="/inventory-summary" className="dropdown-item">
                    <span>Summary</span>
                  </Link>
                </li>

              </ul>
            </li>

            <li className="nav-item">
              <Link to="/customer" className="nav-link">
                <span>Customers</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/categories" className="nav-link">
                <span>Categories</span>
              </Link>
            </li> */}

              {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Admin
              </a>
              <ul className="dropdown-menu">
                
                <li>
                  <Link to="/finances" className="dropdown-item">
                    <span>Finances</span>
                  </Link>
                </li>
                <li>
                  <Link to="/stores" className="dropdown-item">
                    <span>Stores</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Users" className="dropdown-item">
                    <span>Users</span>
                  </Link>
                </li>
              </ul>
            </li> */}
            </ul>

            <span className="navbar-text">
              <div className="collapse navbar-collapse" id="topNavBar">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <Select
                      options={storeOptions}
                      value={selectedStore}
                      onChange={handleStoreChange}
                      placeholder="Select Store"
                    />
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle ms-2"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-person-fill" />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(e) => openPrinterSettingModal(e)}
                        >
                          Printer Settings
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={() => {
                            localStorage.clear();
                            window.location.reload();
                          }}
                        >
                          Log out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </div>
      </nav>
      <PrinterSettings
        printerSettingModal={printerSettingModal}
        togglePrinterSettingModal={togglePrinterSettingModal}
        selectedStore={selectedStore}
        availableReceiptPrinters={availableReceiptPrinters}
        allPrinters={allPrinters}
      />
    </>
  );
};

export default Navbar;
